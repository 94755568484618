import { inject, Injectable, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LangOption } from '../models/lang.model';
import { DatePipe } from '@angular/common';
import { StorageAdapter } from './infrastructure/adapters/storage-adapter';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GeneralService {
  private storage = inject(StorageAdapter);
  routeActive$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  isPaymentSelected$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  membershipSelected$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  showLogin$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  membershipSetup$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  showSignup$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  showRecoverPass$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  showSetNewPass$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  showPopupViewPrice$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  showPopupUpgrade$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  showPaymentSubscription$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  showPopupUpgraded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  showPopupCancelTrip$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  showPopupTripCanceled$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  showPopupWorking$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  showPopupDestination$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  showPopupPriceDetail$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  showPopupGalleryHotel$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  showError$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  iconDestination$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  messageError$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  titleError$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  imageError$: BehaviorSubject<string> = new BehaviorSubject<string>(
    'images/cancel_trip.png'
  );
  btnLabelError$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  btnTxtError$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  private currentStepHotelOptionSubject = new BehaviorSubject<number>(1);
  goToDashboard$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  clickMoreInfo$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  isDowngradeClicked$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  isUpgradeClicked$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  showLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  route$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  titleMobile$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  selectedLanguage$: BehaviorSubject<LangOption> =
    new BehaviorSubject<LangOption>({ label: '', value: '', flag: '' });

  constructor(
    private router: Router,
    private _traslateService: TranslateService
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.route$.next(event.url);
        this.setTitleMobile(event.url);
      }
    });
  }

  isLogged(): boolean {
    return sessionStorage.getItem('session') ? true : false;
  }

  onLogin() {
    this.showLogin$.next(true);
    this.showPopupViewPrice$.next(false);
    this.showSignup$.next(false);
  }

  onSignup(): void {
    this.showSignup$.next(true);
    this.showPopupViewPrice$.next(false);
    this.showLogin$.next(false);
  }

  onLogout(): void {
    this.resetObservables();
    sessionStorage.clear();
    this.router.navigate(['/home']);
  }

  getSessionProperty(property: string): boolean {
    return sessionStorage.getItem(property) != null ? true : false;
  }

  getCurrentStep(): Observable<number> {
    return this.currentStepHotelOptionSubject.asObservable();
  }

  setCurrentStep(step: number) {
    this.currentStepHotelOptionSubject.next(step);
  }

  resetObservables(): void {
    this.clickMoreInfo$.next(false);
    this.goToDashboard$.next(false);
  }

  setTitleMobile(url: string): void {
    switch (url) {
      case '/profile/my-trips':
        this.titleMobile$.next(
          this._traslateService.instant('NAVIGATION.my_trips')
        );
        break;
      case '/profile/my-favorites':
        this.titleMobile$.next(
          this._traslateService.instant('NAVIGATION.my_favorites')
        );
        break;
      case '/profile/membership':
        this.titleMobile$.next(
          this._traslateService.instant('NAVIGATION.membership')
        );
        break;
      case '/profile/my-account':
        this.titleMobile$.next(
          this._traslateService.instant('NAVIGATION.my_account')
        );
        break;
      case '/profile/my-activity':
        this.titleMobile$.next(
          this._traslateService.instant('NAVIGATION.my_activity')
        );
        break;
      case '/dashboard':
        this.titleMobile$.next(
          this._traslateService.instant('DASHBOARD.title')
        );
        break;
      case '/trip':
        this.titleMobile$.next(
          this._traslateService.instant('NAVIGATION.new_trip')
        );
        break;
      case '/home':
        this.titleMobile$.next(
          this._traslateService.instant('NAVIGATION.home')
        );
        break;
      case '/how-it-works':
        this.titleMobile$.next(
          this._traslateService.instant('NAVIGATION.how_it')
        );
        break;
      case '/what-is-travelaya':
        this.titleMobile$.next(
          this._traslateService.instant('NAVIGATION.whats')
        );
        break;
      case '/help':
        this.titleMobile$.next(
          this._traslateService.instant('NAVIGATION.help')
        );
        break;
      case '/accomodation':
        this.titleMobile$.next(
          this._traslateService.instant('NAVIGATION.accomodation')
        );
        break;
      default:
        break;
    }
  }

  dateAccordingToLanguage(date: string): any {
    let datePipe = new DatePipe(
      this.storage.get(environment.storeKeys.USER_LANGUAGE)
    );
    return datePipe.transform(date, 'mediumDate');
  }

  calculateDaysBetweenDates(startDate: string, endDate: string): number {
    // Convertir las fechas en objetos Date
    const fechaInicio = new Date(startDate);
    const fechaFin = new Date(endDate);

    // Calcular la diferencia en milisegundos
    const diferenciaMilisegundos = fechaInicio.getTime() - fechaFin.getTime();

    // Convertir la diferencia de milisegundos a días (1 día = 24 horas * 60 minutos * 60 segundos * 1000 milisegundos)
    const dias = diferenciaMilisegundos / (1000 * 60 * 60 * 24);

    // Retornar el número de días
    return Math.ceil(dias); // Redondear hacia arriba si hay decimales
  }
}
