import { Component, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownModule } from 'primeng/dropdown';
import { LangOption } from 'src/app/core/models/lang.model';
import { environment } from 'src/environments/environment';
import { InternationalizationAdapter } from 'src/app/core/providers/infrastructure/adapters/internationalization-adapter';
import { GeneralService } from 'src/app/core/providers/general.service';
import { FormsModule } from '@angular/forms';
import { StorageAdapter } from 'src/app/core/providers/infrastructure/adapters/storage-adapter';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-language-selector',
  standalone: true,
  imports: [CommonModule, DropdownModule, FormsModule, TranslateModule],
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
})
export class LanguageSelectorComponent implements OnInit {
  langOptions: LangOption[] = environment.selectLanguages;
  selectedLang: LangOption;
  private interLang = inject(InternationalizationAdapter);
  private storage = inject(StorageAdapter);

  constructor(private _generalService: GeneralService) {}
  ngOnInit(): void {
    let userLang = navigator.language;
    let storageLang = this.storage.get(environment.storeKeys.USER_LANGUAGE);
    let findLang = this.findLang(storageLang ? storageLang : userLang);
    this.selectedLang = findLang ? findLang : environment.defaultSelectLanguage;
  }

  findLang(value: any): LangOption {
    return this.langOptions.filter(
      ele => ele.value.split('-')[0] == value.split('-')[0]
    )[0];
  }
  onChangeLange(event: any): void {
    this.selectedLang = this.findLang(event.value.value);
    this._generalService.selectedLanguage$.next(this.selectedLang);
    this.storage.set(
      environment.storeKeys.USER_LANGUAGE,
      this.selectedLang.value.split('-')[0]
    );
    this.interLang.useLanguage(this.selectedLang.value.split('-')[0]);
  }
}
