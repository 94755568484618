<div class="languages">
  <label class="input-label">{{
    'PROFILE.account.form.language' | translate
  }}</label>
  <p-dropdown
    class="w-full"
    [options]="langOptions"
    [(ngModel)]="selectedLang"
    optionLabel="label"
    placeholder=""
    (onChange)="onChangeLange($event)">
    <ng-template pTemplate="selectedItem">
      <div class="flex flex-row justify-content-between align-items-center">
        <div>
          {{ selectedLang.label }}
        </div>

        <img class="lang-change-img" [src]="selectedLang.flag" alt="" /></div
    ></ng-template>
    <ng-template let-country pTemplate="item">
      <div class="flex flex-row">
        <img class="lang-change-img" [src]="country.flag" alt="" />
        <div class="ml-2">
          {{ country.label }}
        </div>
      </div>
    </ng-template>
  </p-dropdown>
</div>
