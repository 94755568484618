import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DashboardMetrics } from 'src/app/core/models/dashboard-metricts.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(private httpClient: HttpClient) {}
  dashboardMetrics$: BehaviorSubject<DashboardMetrics> =
    new BehaviorSubject<DashboardMetrics>(null);

  getMetrics(): Promise<DashboardMetrics> {
    const uuid = sessionStorage.getItem('uuid');
    return new Promise((resolve, reject) => {
      this.httpClient
        .get(`${environment.apiUrl}/dashboard/metrics/${uuid}`)
        .subscribe({
          next: (res: any) => {
            this.dashboardMetrics$.next(res.data);
            resolve(res.data);
          },
          error: e => {
            reject(e);
            console.error(e);
          },
          complete: () => console.info('complete'),
        });
    });
  }

  getSuggestion(): Promise<any> {
    const uuid = sessionStorage.getItem('uuid');
    return new Promise((resolve, reject) => {
      this.httpClient
        .get(`${environment.apiUrl}/dashboard/suggestions`)
        .subscribe({
          next: (res: any) => {
            resolve(res.data);
          },
          error: e => {
            reject(e);
            console.error(e);
          },
          complete: () => console.info('complete'),
        });
    });
  }
}
