import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GeneralService } from '../../general.service';
import { CitiesSearch } from 'src/app/core/models/cities.model';

@Injectable({
  providedIn: 'root',
})
export class DestinationService {
  public zoneSearchs$: BehaviorSubject<CitiesSearch[]> = new BehaviorSubject<
    CitiesSearch[]
  >([]);
  public citiesSearchs$: BehaviorSubject<CitiesSearch[]> = new BehaviorSubject<
    CitiesSearch[]
  >([]);
  public destinySelected$: BehaviorSubject<CitiesSearch> =
    new BehaviorSubject<CitiesSearch>(null);

  constructor(private httpClient: HttpClient) {}

  getDestination(word: string): Observable<any[]> {
    return this.httpClient.get<any[]>(
      `${environment.apiUrl}/booking/search?term=${word}`
    );
  }
}
