/* eslint-disable no-underscore-dangle */
import { Injectable, inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { LoggerAdapter } from './adapters/logger-adapter';
import { StorageService } from './storage.service';
import { InternationalizationAdapter } from './adapters/internationalization-adapter';
import { environment } from 'src/environments/environment';
import { PrimeNGConfig } from 'primeng/api';

/**
 * Handle the proper load of i18n internationalization files
 *
 * @export
 * @class InternationalizationService
 */
@Injectable({
  providedIn: 'root',
})
export class InternationalizationService extends InternationalizationAdapter {
  /**
   * Inject the TranslateService
   *
   * @private
   * @type {TranslateService}
   * @memberof InternationalizationService
   */
  private translateSrv: TranslateService = inject(TranslateService);

  /**
   * Inject the LoggerAdapter
   *
   * @private
   * @type {LoggerAdapter}
   * @memberof InternationalizationService
   */
  private loggerService: LoggerAdapter = inject(LoggerAdapter);

  /**
   * Inject the StorageService
   *
   * @private
   * @type {StorageService}
   * @memberof InternationalizationService
   */
  private storage: StorageService = inject(StorageService);

  /**
   * Inject the Title
   *
   * @private
   * @type {Title}
   * @memberof InternationalizationService
   */
  private titleService: Title = inject(Title);

  /**
   * store the current active language
   *
   * @private
   * @type {string}
   * @memberof InternationalizationService
   */
  private _language: string;

  /**
   * store the current active language
   *
   * @private
   * @type {string}
   * @memberof InternationalizationService
   */
  private locale: string;

  /**
   * Creates an instance of InternationalizationService,
   * set the default language asnd handle tittle changes
   * @memberof InternationalizationService
   */
  constructor(private config: PrimeNGConfig) {
    super();
    this.loggerService.init('InternationalizationService');
    this.checkDefaultLanguage();
    this.handleTitleUpdates();
  }

  /**
   * Subscribe to the Title subscriptions to update the Page title
   *
   * @memberof InternationalizationService
   */
  async handleTitleUpdates(): Promise<void> {
    this.translateSrv.onLangChange.subscribe((event: LangChangeEvent) => {
      // Change page title when user changes language preference
      this.translateSrv.get('title').subscribe((res: string) => {
        this.titleService.setTitle('Travelaya');
      });
      this.translateSrv
        .get('primeng')
        .subscribe(res => this.config.setTranslation(res));
    });
  }

  /**
   * Check if the user has stored the defasult language
   * and if not it set acording to system navigator
   *
   * @private
   * @memberof InternationalizationService
   */
  async checkDefaultLanguage(): Promise<void> {
    this.loggerService.start(
      'InternationalizationService() - checkDefaultLanguage()'
    );
    this.translateSrv.addLangs(environment.languages);
    const lng: string = await this.storage.get(
      environment.storeKeys.USER_LANGUAGE
    );
    if (lng && this.checkAvaibible(lng)) {
      this.loggerService.debug(
        'InternationalizationService - USER_LANGUAGE ' + lng
      );
      this.language = lng;
    } else {
      if (this.checkApiAvaible()) {
        const navigatorLng = navigator.language.substring(0, 2);
        this.loggerService.debug(
          'InternationalizationService - navigator Language ' + navigatorLng
        );
        if (this.checkAvaibible(navigatorLng)) {
          this.language = navigatorLng;
        } else {
          this.language = environment.defaultLanguage;
        }
      } else {
        this.language = environment.defaultLanguage;
      }
    }
    this.setDefaultLng(this.language);
    this.loggerService.end(
      'InternationalizationService() - checkDefaultLanguage()'
    );
    this.translateSrv
      .get('primeng')
      .subscribe(res => this.config.setTranslation(res));
    environment.selectLanguages.forEach(element => {
      if (element.value.split('-')[0] == this.language) {
        this.locale = element.currency;
      }
    });
  }

  /**
   * return an array wiyh the list of all avaibles languages
   *
   * @readonly
   * @type {string[]}
   * @memberof InternationalizationService
   */
  public get languages(): string[] {
    return this.translateSrv.getLangs();
  }

  /**
   * getter for Language propiety
   *
   * @type {string}
   * @memberof InternationalizationService
   */
  public get language(): string {
    return this._language;
  }

  /**
   * Setter for language propiety.
   * It store the user language in SQlite Device DB
   *
   * @memberof InternationalizationService
   */
  public set language(value: string) {
    document.documentElement.lang = value;
    this.storage.remove(environment.storeKeys.USER_LANGUAGE);
    this.storage.set(environment.storeKeys.USER_LANGUAGE, value);
    this._language = value;
  }

  /**
   * get a specific translation string
   *
   * @param {string} key - the index of the desired translation
   * @param {object} [params] - Optional object with the key and value to be assigned to the translation
   * @return {*} {string} - The desired translation string
   * @memberof InternationalizationService
   */
  public getinstant(key: string, params?: object): any {
    return this.translateSrv.instant(key, params);
  }

  /**
   * Check if the browser API for localization is avaible
   * acording to HTML 5,1 specifications https://html.spec.whatwg.org/multipage/system-state.html#language-preferences
   *
   * @return boolean
   * @memberof InternationalizationService
   */
  checkApiAvaible(): boolean {
    const avaible = window.Intl && typeof window.Intl === 'object';
    if (avaible) {
      this.loggerService.debug('InternationalizationService - Api Available  ');
    }
    return avaible;
  }

  /**
   * check if a localization file exist and return a boolean
   *
   * @param {string} lng -  the localization keyword to check (en - es)
   * @return {*} {boolean} - true if the localization file exist
   * @memberof InternationalizationService
   */
  checkAvaibible(lng: string): boolean {
    return this.translateSrv.getLangs().includes(lng);
  }

  /**
   * Set the default localization language to a specific language
   *
   * @param lng the localization key to set as default (en -es)
   * @memberof InternationalizationService
   */
  setDefaultLng(lng: string): void {
    this.translateSrv.setDefaultLang(lng);
    this.translateSrv
      .get('primeng')
      .subscribe(res => this.config.setTranslation(res));
    this.loggerService.debug(
      'InternationalizationService - setDefaultLang  ' + lng
    );
  }

  /**
   * Use a specific localization language
   *
   * @param {string} lng the localization key to use (en -es)
   * @memberof InternationalizationService
   */
  useLanguage(lng: string): void {
    if (lng) {
      const lang: string = this.checkAvaibible(lng.split('-')[0])
        ? lng
        : environment.defaultLanguage;
      this.language = lang;
      this.translateSrv.use(lang);
      this.translateSrv.setDefaultLang(lang);
      this.translateSrv
        .get('primeng')
        .subscribe(res => this.config.setTranslation(res));
      environment.selectLanguages.forEach(element => {
        if (element.value.split('-')[0] == lang) {
          this.locale = element.currency;
        }
      });
    }
  }

  /**
   * Return a translation with its corresponding value
   * asociated to a data objet
   *
   * @param {string} label - The translation key to use as label
   * @param {(number | string)} value - the number value to concatenate
   * @return {*}  {string} - String with the translated label and the value
   * @memberof InternationalizationService
   */
  setValueAndLabel(label: string, value: number | string): string {
    let val: string;
    val = this.getinstant(label) + ': ';
    if (typeof value === 'string') {
      val += value;
    } else {
      val += value.toLocaleString('es', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });
    }
    return val;
  }

  /**
   * Use a specific localization language
   *
   * @param {string} lng the localization key to use (en -es)
   * @memberof InternationalizationService
   */
  getLocale(): string {
    return this.locale;
  }
}
