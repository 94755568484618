<div class="last-search">
  <p class="title">
    {{ 'DASHBOARD.last_searchs.title' | translate }}
  </p>
  <div class="scroll">
    <div *ngFor="let lastSearch of lastSerachs.slice(0, 3)">
      <div class="trip-information">
        <div class="flex flex-column">
          <span class="color-light-text lb-date mb-1 mt-2">
            {{
              lastSearch.startDate
                | date : 'mediumDate' : '' : (locale$ | async)
            }}
          </span>
          <span class="color-black lb-location mb-1"
            >{{ lastSearch.city_name }} - {{ lastSearch.region_name }} -
            {{ lastSearch.country_name }}</span
          >
          <span class="lb-guest mb-2">
            {{ +lastSearch.adults + +lastSearch.kids + +lastSearch.babies }}
            {{ 'PROFILE.historic.person' | translate }}
          </span>
        </div>
        <div>
          <span class="btn-go" (click)="goToAccomodations(lastSearch)">{{
            'GENERAL.btn_go' | translate
          }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
