import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SidebarLoginSignupComponent } from '../components/shared/shared-general/sidebar-login-signup/sidebar-login-signup.component';
import { SidebarLoginSignupService } from '../core/providers/public/sidebar-login-signup/sidebar-login-signup.service';
import { DialogModule } from 'primeng/dialog';
import { GeneralService } from '../core/providers/general.service';
import { SidebarPrivateService } from '../core/providers/private/sidebar-private/sidebar-private.service';
import { SidebarPrivateComponent } from '../components/shared/shared-private/sidebar-private/sidebar-private.component';
import { PopupViewPriceComponent } from '../components/trip/shared-flow/popup-view-price/popup-view-price.component';
import { NewPaymentModalComponent } from '../components/trip/shared-flow/new-payment-modal/new-payment-modal.component';
import { PaymentSubscriptionModalComponent } from '../components/trip/shared-flow/payment-subscription-modal.component/payment-subscription-modal.component';
import { PopupUpgradedComponent } from '../components/trip/shared-flow/popup-upgraded/popup-upgraded.component';
import { PopupCancelTripComponent } from '../components/trip/shared-flow/popup-cancel-trip/popup-cancel-trip.component';
import { PopupTripCanceledComponent } from '../components/trip/shared-flow/popup-trip-canceled/popup-trip-canceled.component';
import { SignupService } from '../core/providers/public/signup/signup.service';
import { PopupWorkingComponent } from '../components/shared/shared-general/popup-working/popup-working.component';
import { ExpCardFormatterDirective } from '../core/directives/exp-card.directive';
import { PopupErrorComponent } from '../components/shared/shared-general/popup-error/popup-error.component';
import { Observable } from 'rxjs';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ToastModule } from 'primeng/toast';
import { LoginComponent } from '../components/shared/shared-general/login/login.component';
import { RecoverPassComponent } from '../components/shared/shared-general/recover-pass/recover-pass.component';
import { SignUpComponent } from '../components/shared/shared-general/sign-up/sign-up.component';
import { PopupDestinationMobileComponent } from '../components/shared/shared-general/popup-destination-mobile/popup-destination-mobile.component';
import { GalleryHotelComponent } from '../components/trip/flow/gallery-hotel/gallery-hotel.component';
import { SetNewPassComponent } from '../components/shared/shared-general/recover-pass/set-new-pass/set-new-pass.component';

@Component({
  selector: 'app-main',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    ExpCardFormatterDirective,
    SidebarLoginSignupComponent,
    SidebarPrivateComponent,
    DialogModule,
    LoginComponent,
    SignUpComponent,
    PopupViewPriceComponent,
    NewPaymentModalComponent,
    PaymentSubscriptionModalComponent,
    PopupUpgradedComponent,
    PopupCancelTripComponent,
    PopupTripCanceledComponent,
    PopupWorkingComponent,
    PopupErrorComponent,
    ProgressSpinnerModule,
    RecoverPassComponent,
    SetNewPassComponent,
    ToastModule,
    PopupDestinationMobileComponent,
    GalleryHotelComponent,
  ],
  providers: [],
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit {
  visibleLogin = false;
  visibleSignup = false;
  visibleRecoverPass = false;
  visibleSetNewPass = false;
  visiblePopupViewPrice = false;
  visibleUpgrade = false;
  visiblePaymentSubscription = false;
  visibleUpgraded = false;
  visibleCancelTrip = false;
  visibleTripCanceled = false;
  visibleWorking = false;
  visibleErrors = false;
  visibleLoading = true;
  visibleDestination = false;
  visiblePriceDetail = false;
  visibleGallery = false;
  membershipSetup = false;
  membershipSelected = false;
  messageError$: Observable<string>;
  titleError$: Observable<string>;
  imageError$: Observable<string>;
  btnLabelError$: Observable<string>;
  btnTxtError$: Observable<string>;
  medias: any;

  constructor(
    private _translateService: TranslateService,
    public _sidebarLoginSignupService: SidebarLoginSignupService,
    public _sidebarPrivateService: SidebarPrivateService,
    public _generalService: GeneralService,
    public _signupService: SignupService,
    public cdr: ChangeDetectorRef
  ) {
    this.messageError$ = this._generalService.messageError$;
    this.titleError$ = this._generalService.titleError$;
    this.imageError$ = this._generalService.imageError$;
    this.btnLabelError$ = this._generalService.btnLabelError$;
    this.btnTxtError$ = this._generalService.btnTxtError$;
  }

  ngOnInit(): void {
    this._generalService.showLogin$.subscribe(res => {
      this.visibleLogin = res;
    });
    this._generalService.showSignup$.subscribe(res => {
      this.visibleSignup = res;
    });
    this._generalService.showRecoverPass$.subscribe(res => {
      this.visibleRecoverPass = res;
    });

    this._generalService.showSetNewPass$.subscribe(res => {
      this.visibleSetNewPass = res;
    });
    this._generalService.showPopupViewPrice$.subscribe(res => {
      this.visiblePopupViewPrice = res;
    });
    this._generalService.showPopupUpgrade$.subscribe(res => {
      this.visibleUpgrade = res;
      this.cdr.detectChanges();
    });
    this._generalService.showPaymentSubscription$.subscribe(res => {
      this.visiblePaymentSubscription = res;
      this.cdr.detectChanges();
    });
    this._generalService.showPopupUpgraded$.subscribe(res => {
      this.visibleUpgraded = res;
    });
    this._generalService.showPopupCancelTrip$.subscribe(res => {
      this.visibleCancelTrip = res;
    });
    this._generalService.showPopupTripCanceled$.subscribe(res => {
      this.visibleTripCanceled = res;
    });
    this._generalService.showPopupWorking$.subscribe(res => {
      this.visibleWorking = res;
    });
    this._generalService.showError$.subscribe(res => {
      this.visibleErrors = res;
    });
    this._generalService.membershipSelected$.subscribe(res => {
      this.membershipSelected = res;
      this.cdr.detectChanges();
    });
    this._generalService.membershipSetup$.subscribe(res => {
      this.membershipSetup = res;
    });
    this._generalService.showLoading$.subscribe(res => {
      this.visibleLoading = res;
      this.cdr.detectChanges();
    });
    this._generalService.showPopupDestination$.subscribe(res => {
      this.visibleDestination = res;
    });
    this._generalService.showPopupPriceDetail$.subscribe(res => {
      this.visiblePriceDetail = res;
    });

    this._generalService.showPopupGalleryHotel$.subscribe(res => {
      res ? (this.visibleGallery = true) : (this.visibleGallery = false);
      this.medias = res;
    });
  }

  closeDialog(): void {
    this._generalService.clickMoreInfo$.next(false);
    this._generalService.goToDashboard$.next(false);
  }

  newAccount(): void {
    this._generalService.showPopupUpgrade$.next(false);
    this._generalService.showPaymentSubscription$.next(true);
  }
}
